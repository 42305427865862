import axios from 'axios';

// In client-to-origin communication, user auth is handled explicitly
// by passing the user's serverAccessToken along with each request.
// The origin server will read this token, and pass it to sidekick
const token = window.Sidekick.decode('sidekick.serverAccessToken');
if (!token) {
  throw new Error('Failed to decode server access token');
}

const origin = axios.create({
  baseURL: process.env.NODE_ENV === 'production'
    ? 'https://api.sftp.sidekickdelivery.com'
    : 'http://localhost:8180',
  responseType: 'json',
  headers: {
    'X-Sidekick-Token': token,
    'X-Sidekick-Username': getUsername()
  }
});

// When creating a SFTP user, the username is derived from:
//   1. The current rails environment
//   2. The current site abbreviation
//   3. The current user's id
// 
// For example: development_jfh_374

function getUsername() {
  // This delimiter is an important assumption when creating this username
  // The SFTP importer will split the username on the same delimiter
  // If you update the delimiter here for some reason, make sure to update it in the importer
  const delimiter = '_'
  
  const environment = window.Sidekick.decode('sidekick.environment');
  const site = window.Sidekick.decode('sidekick.site');
  const userId = window.Sidekick.decode('sidekick.userId');

  if (!environment || !site || !userId) {
    throw new Error('Failed to decode username components');
  }

  return [ environment, site, userId ].join(delimiter)
}

export async function getUser() {
  const { data } = await origin.get(`users/${getUsername()}`)
  return data.data.user;
}

export async function createUser () {
  const { data } = await origin.post('/users', {
    username: getUsername()
  })
  return data.data.user;
}

export async function updateUser ({ password, publicKey }) {
  const { data } = await origin.post(`/users/${getUsername()}`, {
    password, publicKey
  })
  return data.data.user;
}
